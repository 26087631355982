<template>
    <div :class="containerClass">
        <button 
            ref="button"
            type="button"
            :id="id"
            :aria-label="arialabel"
            :class="[buttonClass || '', 
                disabled ? 'no-events' : '',
                mobileIcon ? 'mobile-icon': '',
                IsLoading ? 'loading': '',
                IsResult ? 'result' : '']"
            @click="clickHanlder">

                <img
                    alt="Mobile" 
                    :src="mobileIcon" 
                    v-if="mobileIcon && mq.current == 'mobile'"
                    class="icon"/>
                <span class="broken" v-else>{{text}}</span>
                
                <span ref="progressBar" id="progressBar" class="progress-bar">
                    <img :src="doneIcon" class="done-icon">
                </span>
        </button>
        <transition name="fadein">
            <p 
                v-if="IsError"
                class="error-message small-text">{{texts.form.buttons.errorMessage}}</p>
        </transition>
    </div>
</template>

<script>
    import Velocity from 'velocity-animate'
    import text from '../../assets/text/text'

    export default {
        inject: ['mq'],
        name: 'ReButton',
        props: {
            id: String,
            name: String,
            text: String,
            arialabel: String,
            isLoading: Boolean,
            progressValue: Number,
            buttonClass: String,
            containerClass: String,
            disabled: Boolean,
            mobileIcon: String
        },
        data: () => ({
            texts: text,
            doneIcon: require('@/assets/img/done.svg'),
            startingPoint: 0,
            IsLoading: false,
            IsResult: false,
            IsError: false
        }),
        methods: {
            clickHanlder: function(){
                /** Disabled to avoid double event triggering ******/
                //this.$emit('click')
                this.IsError = false
            },
            complete: function(){
                this.IsLoading = false
                this.IsResult = true
                this.$emit('updateCurrentState', this.name)
            },
            animate: function(_f, _s){
                const { progressBar } = this.$refs
                
                var self = this
                var _d = _f == 1 ? 200 : 4000

                Velocity(progressBar,"finish")
                Velocity(progressBar, 
                    { 
                        scaleX: [ _f , _s] ,
                        scaleY: 1
                    }, 
                    { 
                        easing: "linear", 
                        duration: _d, 
                        delay: 500,
                        complete: function(){
                            if(_f == 1){
                                self.complete()
                            }
                        }
                    })
            },
            resetStatus: function(){
                this.IsLoading = false
                this.IsResult = false
                this.startingPoint = 0;
            },
            errorHandler: function(){
                const { progressBar } = this.$refs
                this.IsLoading = false
                this.IsResult = false
                this.IsError = true
                Velocity(progressBar,"finish")
                this.startingPoint = 0;
            }
        },
        watch: {
            progressValue: function(){
                this.animate(this.progressValue, this.startingPoint)
                this.startingPoint = this.progressValue
            },
            isLoading: function(){
                this.IsLoading = this.isLoading
            }
        }
    }
</script>

<style scoped>

</style>