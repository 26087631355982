<template>
    <div class="section-skeleton">
        <div 
            ref="content"
            class="section-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SectionSkeleton'
    }
</script>
