<template>
  <div
    class="r-field"
    :class="{
      active: active,
      error: error,
      open: open,
    }"
    v-on="button ? { click: clickHandler } : {}"
  >
    <label :id="name+'Q'" class="r-field-label">{{ placeholder }}</label>
    <input
      :type="button ? 'button' : 'text'"
      class="r-field-input"
      :aria-labelledby="name+'Q'"
      v-model="value"
      :id="name"
      :maxlength="maxLength"
      :inputmode="inputMode"
      :pattern="inputPattern"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      autocomplete="off"
      autocapitalize="off"
      spellcheck="false"
      autocorrect="off"
    />
    <div v-if="button" class="r-field-down">
      <img alt="Apri" :src="down" :class="{ open: isOpen }" />
    </div>

    <div v-if="error" class="r-field-error"><img :src="alert" /></div>
  </div>
</template>

<script>
import { arrayProvince } from "../../helpers/utils.js";

import {
  onlyNumber,
  onlyChar,
  contattoHelper,
  telefonoHelper,
  pivaHelper,
  includesHelper,
  parsePhoneNumber,
} from "../../helpers/helper.js";
export default {
  name: "TextInputs",
  props: {
    name: String,
    placeholder: String,
    type: String,
    maxLength: String,
    inputMode: String,
    inputPattern: String,
    button: Boolean,
    isOpen: Boolean,
  },
  data: () => ({
    active: false,
    open: false,
    error: false,
    value: "",
    valueHelper: undefined,
    provinceHelper: undefined,
    alert: require("@/assets/img/alert.svg"),
    down: require("@/assets/img/ico-func-dark-chevron-down.svg"),
  }),
  created: function () {
    this.provinceHelper = () => 1 == 1;

    switch (this.name) {
      case "contatto":
        this.valueHelper = contattoHelper;
        break;
      case "telefono":
        this.valueHelper = telefonoHelper;
        break;
      case "provincia":
        this.provinceHelper = (_v) => includesHelper(_v, arrayProvince);
      // eslint-disable-next-line
      case "ragione_sociale":
      case "quando":
        this.valueHelper = (_v) => _v && _v.length > 0;
        break;
      case "partita_iva":
        //this.valueHelper = (_v) => _v.length == 0 || _v.length == 11;
        this.valueHelper = pivaHelper;
        break;
      case "referente":
        this.valueHelper = (_v) => {return _v.length > 3 || _v.length == 0};
        break;
      default:
        this.valueHelper = () => true;
    }
  },
  methods: {
    onFocus: function () {
      this.active = true;
      this.open = true;
    },
    onBlur: function () {
      this.active = false;
      this.checkValue();

      if (this.button) this.$emit("close");

      setTimeout(() => {
        if (this.value.length == 0) this.open = false;
      }, 10);
    },
    onInput: function (evt) {
      let value = evt.target.value || this.value;
      this.updateValue(value);
      if (this.valueHelper(value)) this.error = false;
    },
    updateValue: function (v) {
      switch (this.type) {
        case "number":
          this.value = onlyNumber(v);
          break;
        case "string":
          this.value = onlyChar(v);
          break;
        default:
          this.value = (this.name != "contatto" && this.name != "telefono") ? v : this.phoneNumberParser(v);
      }

      this.$emit("onchange", this.printData(this.name, this.value));
    },
    checkValue: function () {
      if (this.valueHelper(this.value)) {
        this.error = false;
      } else {
        this.error = true;
      }

      return this.error;
    },
    checkValueIncludes: function () {
      if (this.valueHelper(this.value) && this.provinceHelper(this.value)) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
    printData: function (_n, _v) {
      if (!this.valueHelper(_v)) _v = "";
      return { name: _n, value: _v };
    },
    cleanData: function () {
      this.setValue("");
      this.error = false;
      this.open = false;
    },
    setValue: function (v) {
      this.updateValue(v);
      if (v.length > 0) this.open = true;
    },
    clickHandler: function () {
      this.$emit("onclick");
    },
    phoneNumberParser: function (value) {
      var retValue = value;
      try {
        let parsedNum = parsePhoneNumber(value, "IT");
        //console.log('isValidNumber: ', this.PNU.isValidNumber(telnum));
        if (parsedNum.isValid()) {
          retValue = parsedNum.formatNational();
          //console.log("piped: ", retValue);
        }
      } catch (error) {
        //console.log('fail', error.message);
      }
      return retValue;
    },
  },
};
</script>