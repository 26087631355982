<template>
    <div ref="container">
        <div 
            ref="parallax" 
            class="parallax"
            :style="{top: heroHeight + 'px'}">
            <div class="background"></div>
        </div>

        <div ref="section">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/*
import { TweenMax, TimelineMax , Linear } from 'gsap'
import ScrollMagic from 'scrollmagic'
import 'ScrollMagicGSAP'
import 'ScrollMagicIndicators'
*/


export default {
        inject: ['mq'],
        name: 'Parallax',
        components: {
        },
        data: () => ({
            childrenHeight: '0',
            heroHeight: 0
        }),
        mounted: function() {
            //const { parallax, container } = this.$refs

            window.addEventListener('resize', this.heroHeightHandler)
            this.heroHeightHandler()
            
            /*
            const controller = new ScrollMagic.Controller();
            let timeline = new TimelineMax();


            window.addEventListener('resize', this.handleResize)
            this.handleResize();

            
            timeline.add( TweenMax.to(parallax.children[0], 1, {y: "10%", ease: Linear.easeNone }) )
            timeline.add( TweenMax.to(parallax, 1, {y: "-10%", ease: Linear.easeNone }), 0 )
            
            
            new ScrollMagic.Scene({ triggerElement: container, triggerHook: "onEnter", duration: "300%"})
                .setTween(timeline)
                .addIndicators()
                .addTo(controller)
            */
            
        },
        destroyed() {
            window.removeEventListener('resize', this.heroHeightHandler)
        },
        methods: {
            handleResize: function (){
                const { section } = this.$refs
                this.childrenHeight = section.offsetHeight + 'px';
            },
            heroHeightHandler: function(){
                if(this.mq.current == 'mobile'){
                    //let height = document.getElementById('heroContainer').clientHeight;
                    //console.log('heroContainer height:', height);
                    //this.heroHeight = height > 0 ? height : 0
                    this.heroHeight = 0;
                }else{
                    if(this.heroHeight != 0)
                        this.heroHeight = 0
                }
            }
        },
}
</script>