<template>
  <div class="re-form" :class="form.state.current == 0 ? 'form--small--version' : ''">
    <transition :name="fullscreeForm ? 'fullscreen-form' : 'form'">
      <div class="form-container" v-show="form.state.current != 3 && !form.state.showResult">
        <h5 role="presentation" class="title form-title" v-html="text.form.title"></h5>

        <transition name="fadein">
          <p class="standard-light-text description-text min-40" v-html="this.form.state.current < 2
              ? text.form.descriptionMandatory
              : text.form.descriptionOptional
            " v-if="((this.mq.current != 'mobile' &&
      this.mq.current != 'tabletPortrait') ||
      this.form.state.current !== 0) &&
    !callYouSection
    "></p>
        </transition>

        <transition name="fadein">
          <p class="standard-light-text description-text" v-html="text.form.phoneContact.description" v-if="((this.mq.current != 'mobile' &&
              this.mq.current != 'tabletPortrait') ||
              this.form.state.current !== 0) &&
            callYouSection
            "></p>
        </transition>

        <div class="form-body">
          <transition :name="callYouSection ? 'fade' : 'change-first-state-form'">
            <div class="container-action" :class="form.state.current == 0 ? 'small--version' : ''"
              v-show="form.state.current < 2 && !callYouSection">
              <r-text-input ref="contatto" name="contatto" :placeholder="text.form.fields.contatto.placeholder"
                @onchange="inputHandler"></r-text-input>

              <transition name="fadein">
                <div class="form--state-1" v-show="form.state.current == 1">
                  <div class="fields-row">
                    <r-text-input
                      ref="ragione_sociale"
                      name="ragione_sociale"
                      class="main"
                      :placeholder="text.form.fields.ragioneSociale.placeholder"
                      @onchange="inputHandler"
                    >
                    </r-text-input>

                    <v-autocomplete :class="'autocProvincia'" ref="provinciaField" :items="anaProvince" item-title="value"
                      item-value="id" :placeholder="text.form.fields.provincia.placeholder" variant="underlined" :menu="false" filter-mode="'every'"
                      density="default" @update:modelValue="updateProvincia"></v-autocomplete>
                  </div>

                  <div class="fields-privacy">
                    <r-checkbox-input ref="form_acknowledgment_accepted" name="acknowledgment_accepted"
                      type="acknowledgment" state="form" :text="text.form.privacy.acknowledgment('form')"
                      @onchange="inputHandler">
                    </r-checkbox-input>
                    <r-checkbox-input ref="form_marketing_accepted" name="marketing_accepted" type="marketing"
                      state="form" :text="text.form.privacy.marketing('form')" @onchange="inputHandler">
                    </r-checkbox-input>
                  </div>

                  <re-button ref="sendMandatory" name="sendMandatory" containerClass="btn-container"
                    buttonClass="btn btn-red full" :id="text.form.buttons.state_1.id"
                    :isLoading="form.state.sendMandatory.progressBar"
                    :progressValue="form.state.sendMandatory.progressValue" :text="text.form.buttons.state_1.placeholder"
                    :arialabel="text.form.buttons.state_1.placeholder" :disabled="form.state.sendMandatory.disabled"
                    @updateCurrentState="updateState" @click="() => {
                        this.sendClickHandler();
                      }
                      ">
                  </re-button>
                </div>
              </transition>

              <re-button v-if="form.state.current == 0" containerClass="btn-container"
                buttonClass="btn btn-red full no-events-red" :id="text.form.buttons.state_0.id"
                :text="text.form.buttons.state_0.placeholder" :arialabel="text.form.buttons.state_0.placeholder"
                :disabled="form.state.disabledAhead" :mobileIcon="leftArrow" @click="aheadClickHandler">
              </re-button>
            </div>
          </transition>

          <transition name="change-second-state-form">
            <div class="container-action" v-show="form.state.current == 2 && !callYouSection">
              <r-text-input ref="referente" name="referente" :placeholder="text.form.fields.referente.placeholder"
                @onchange="inputHandler">
              </r-text-input>

              <div class="fields-row">

                <v-select ref="settore_merceologicoField" :class="'selectSettore'" :items="anaSettori" item-title="value"
                  item-value="id" :placeholder="text.form.fields.settoreMerceologico.placeholder" density="default"
                  variant="underlined" @update:modelValue="updateSettoreMerceologico"></v-select>

                <r-text-input ref="partita_iva" name="partita_iva" class="secondary" inputMode="numeric"
                  inputPattern="[0-9]*" maxLength="11" type="number"
                  :placeholder="text.form.fields.partitaIva.placeholder" @onchange="inputHandler">
                </r-text-input>
              </div>

              <!--r-options-- ref="interested" :placeholder="text.form.fields.interesse.placeholder"
                :options="text.form.fields.interesse.options" @onchange="inputHandler"></!--r-options-->

              <re-button ref="sendOptional" name="sendOptional" containerClass="btn-container"
                buttonClass="btn btn-red full" :id="text.form.buttons.state_2.id"
                :text="text.form.buttons.state_2.placeholder" :arialabel="text.form.buttons.state_2.placeholder"
                :disabled="form.state.sendOptional.disabled" :isLoading="form.state.sendOptional.progressBar"
                :progressValue="form.state.sendOptional.progressValue" @updateCurrentState="updateState" @click="() => {
                    this.sendOptionalClickHandler();
                  }
                  ">
              </re-button>
            </div>
          </transition>

          <transition name="vertical">
            <div v-show="callYouSection" class="call-you-form">
              <transition name="result-right">
                <div v-show="!form.state.showResult">
                  <r-text-input ref="telefono" name="telefono" inputMode="numeric" :placeholder="text.form.phoneContact.fields.telefono.placeholder
                    " @onchange="inputHandler">
                  </r-text-input>

                  <v-select ref="quandoField" :class="'selectQuando'" name="quando" :items="anaCallMe" item-title="value"
                    :placeholder="text.form.phoneContact.fields.quando.placeholder
                      " density="comfortable" variant="underlined" @update:modelValue="updateCallMe"></v-select>

                  <div class="fields-privacy">
                    <r-checkbox-input ref="call_acknowledgment_accepted" name="acknowledgment_accepted"
                      type="acknowledgment" state="call" :text="text.form.privacy.acknowledgment('call')"
                      @onchange="inputHandler">
                    </r-checkbox-input>
                    <r-checkbox-input ref="call_marketing_accepted" name="marketing_accepted" type="marketing"
                      state="call" :text="text.form.privacy.marketing('call')" @onchange="inputHandler">
                    </r-checkbox-input>
                  </div>

                  <re-button ref="sendCallMe" name="sendCallMe" containerClass="btn-container"
                    buttonClass="btn btn-red full" :text="text.form.phoneContact.button.placeholder"
                    :arialabel="text.form.phoneContact.button.placeholder" :id="text.form.phoneContact.button.id"
                    :disabled="form.state.sendCallMe.disabled" :isLoading="form.state.sendCallMe.progressBar"
                    :progressValue="form.state.sendCallMe.progressValue" @updateCurrentState="updateState" @click="() => {
                        this.callmeClickHandler();
                      }
                      ">
                  </re-button>

                  <a class="call-us no-decoration" :id="text.form.phoneContact.callus.id"
                    :href="text.form.phoneContact.callus.action" @click="() => {
                        sasClick(text.form.phoneContact.callus.id);
                        this.tracking('clicktocall');
                      }
                      ">
                    <p class="small-title broken" v-html="text.form.phoneContact.callus.title"></p>
                    <div class="click-container broken">
                      <div>
                        <p class="big-title" v-html="text.form.phoneContact.callus.number"></p>
                        <p class="small-text grey" v-html="text.form.phoneContact.callus.info"></p>
                      </div>
                      <img alt="Telefono" class="call-icon" src="~@/assets/img/ico-func-red-phone-forwarded.svg" />
                    </div>
                  </a>
                </div>
              </transition>
            </div>
          </transition>

          <call-me ref="callme" @click="changeFormHandler('callMeButton')" v-show="this.mq.current === 'desktop' ||
            this.mq.current === 'bigDesktop' ||
            this.mq.current === 'tabletLandscapeLarge' ||
            this.form.state.current > 0
            ">
          </call-me>
        </div>
      </div>
    </transition>

    <transition :name="fullscreeForm ? 'fullscreen-result-state' : 'result-state'">
      <div class="container-action final-result" v-show="form.state.current == 3 || form.state.showResult">
        <img src="~@/assets/img/ico-thanks.svg" />
        <p class="semi-big-title">{{ text.form.result.title }}</p>
        <p class="standard-text">{{ text.form.result.description }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import text from "../../assets/text/text";
import RTextInput from "../RTextInput/RTextInput";
import RCheckboxInput from "../RCheckboxInput/RCheckboxInput";
//import ROptions from "../ROptions/ROptions";
import ReButton from "../ReButton/ReButton";
import CallMe from "../CallMe/CallMe";
import axios from "axios";
import { EventBus } from "../../helpers/event-bus.js";
import { callMe, settore, province } from "../../helpers/utils.js";

import {
  contattoHelper,
  telefonoHelper,
  viewportHeight,
  withDevelopmentEnv,
  withProductionEnv,
  checkValidValue,
  checkVATCode,
  cloneObj,
  dispatchCurrentFormShape,
  dispatchFormActive

} from "../../helpers/helper.js";
import { sections } from "../../helpers/sections.js";

import { tracking, sasForm, sasClick } from "../../helpers/tracking.js";

export default {
  inject: ["mq"],
  name: "ReForm",
  components: {
    ReButton,
    CallMe,
    RTextInput,
    RCheckboxInput,
    //ROptions,
  },
  data: () => ({
    anaSettori: settore,
    anaCallMe: callMe,
    anaProvince: province,
    text: text,
    callYouSection: false,
    leftArrow: require("@/assets/img/ico-func-white-arrow-right.svg"),
    fullscreeForm: false,
    form: {
      state: {
        current: 0,
        disabledAhead: true,
        sendMandatory: {
          disabled: true,
          progressBar: false,
          progressValue: 0,
        },
        sendOptional: {
          disabled: true,
          progressBar: false,
          progressValue: 0,
        },
        sendCallMe: {
          disabled: true,
          progressBar: false,
          progressValue: 0,
        },
        showResult: false,
      },
      data: {
        request: {
          mandatory: {
            contatto: undefined,
            ragione_sociale: undefined,
            multiStepCode: "CNS",
            provincia: undefined,
          },
          optional: {
            referente: undefined,
            settore_merceologico: undefined,
            partita_iva: undefined,
            elettricita_e_gas: undefined,
            mobilita_elettrica: undefined,
            efficienza_energetica: undefined,
            multiStepCode: "CNS",
            repower_charging_net: true
          },
        },
        callme: {
          telefono: undefined,
          quando: undefined,
          multiStepCode: "CNT",
        },
        privacy: {
          acknowledgment_accepted: false,
          marketing_accepted: false,
        },
        referrer: undefined,
      },
    },
    cancelRequest: undefined,
    timeoutTimer: undefined,
    timeoutSending: undefined,
    requestToSend: {
      folID: 104,
    },
    callmeToSend: {
      folID: 104,
    },
  }),
  created: function () {
    switch (this.name) {
      case "quando":
        this.items = callMe;
        break;
      case "settore_merceologico":
        this.items = settore;
        break;
      default:
        break;
    }
    withDevelopmentEnv(function () {
      document.cookie = "_SI_DID_1.69f24a174700010101d6cf73=fakeSASforDEV";
    });
    this.requestToSend.id = this.makeid();
    window.FOL_contact_session = this.requestToSend.FOL_contact_session = this.requestToSend.id;
    this.callmeToSend.id = this.makeid();
    window.FOL_callme_session = this.callmeToSend.FOL_callme_session = this.callmeToSend.id;
    this.getSASidentity();
    this.form.data.referrer = this.getReferrer();
    document.cookie = "_RE9348q=ce20e727c51d1bb33e54c7c6447c996d279f4613; SameSite=None; domain=repower.com; Secure";
  },
  methods: {
    dispatchFormShape: dispatchCurrentFormShape,
    dispatchFormIsActive: dispatchFormActive,
    changeFormHandler: function (origin) {
      this.callYouSection = !this.callYouSection;
      //this.callYouSection = false;

      //console.log(origin, this.callYouSection);

      if (origin !== void 0 && origin == "callMeButton") {
        if (this.callYouSection) {
          this.dispatchFormShape(2);
        } else {
          if (this.form.state.current == 0) {
            this.dispatchFormShape(0);
          } else {
            this.dispatchFormShape(1);
          }
        }
      }

      if (this.callYouSection) {
        switch (this.form.state.current) {
          case 2:
            this.resetSecondState();
          // eslint-disable-next-line
          case 1:
            this.resetFirstState();
          // eslint-disable-next-line
          case 0:
            this.resetZeroState();
            // eslint-disable-next-line
            break;
          default:
            break;
        }
      } else {
        this.resetCallMe();
      }
    },
    updateProvincia: function (p) {
      //console.log(`provincia updated with: ${p}`);
      this.form.data.request.mandatory.provincia = p;
      this.checkValueSecondState();
    },
    updateSettoreMerceologico: function (s) {
      //console.log('settore m updated with', s);
      this.form.data.request.optional.settore_merceologico = s;

    },
    updateCallMe: function (q) {
      //console.log(q);
      let m = {
        name: "quando",
        value: q,
      };
      this.callMeHandler(m);
    },
    inputHandler: function (m) {
      switch (m.name) {
        case "contatto":
          this.firstStateHandler(m);
          break;
        case "ragione_sociale":
          this.secondStateHandler(m);
          break;
        case "provincia":
          this.secondStateHandler(m);
          break;
        case "acknowledgment_accepted":
        case "marketing_accepted":
          this.privacyStateHandler(m);
          break;
        case "telefono":
          this.callMeHandler(m);
          break;
        case "quando":
          this.callMeHandler(m);
          break;
        default:
          this.optionalStateHandler(m);
          break;
      }
    },
    aheadClickHandler: function () {
      //enabled for test sake
      if (!this.form.state.disabledAhead) {
        this.form.state.current = 1;
        this.dispatchFormShape(this.form.state.current);

        if (
          this.mq.current === "tabletLandscapeSmall" ||
          this.mq.current === "tabletPortrait" ||
          this.mq.current === "mobile"
        )
          this.openFormHandler();
      } else {
        this.$refs.contatto.checkValue();
      }
      this.tracking('goahead');
    },
    sendClickHandler: function () {
      let buttonName = "sendMandatory";

      /******** true for testing purpose *****************/
      if (!this.form.state.sendMandatory.disabled) {
        // eslint-disable-next-line
        this.requestToSend.acknowledgment_accepted = this.form.data.privacy
          .acknowledgment_accepted
          ? true
          : false;
        this.requestToSend.marketing_accepted = this.form.data.privacy
          .marketing_accepted
          ? true
          : false;
        this.requestToSend.SASidentity =
          window.SASidentity == void 0 ? "UNDEFINED" : window.SASidentity;
        this.requestToSend.requestType = "consulenza";
        this.requestToSend.topic = "RCN";
        this.requestToSend.referrer = this.form.data.referrer;
        this.localToData(this.form.data.request.mandatory, this.requestToSend);
        /********* disable for testing purpose **************/
        this.sendFol(this.requestToSend, buttonName);
      } else {
        this.$refs.contatto.checkValue();
        this.$refs.ragione_sociale.checkValue();
        //this.$refs.provincia.checkValue();
        // privacy check
      }
    },
    callmeClickHandler: function () {
      let buttonName = "sendCallMe";

      //check it as TRUE for testing porpose
      if (!this.form.state.sendCallMe.disabled) {
        this.callmeToSend.acknowledgment_accepted = this.form.data.privacy
          .acknowledgment_accepted
          ? true
          : false;
        this.callmeToSend.marketing_accepted = this.form.data.privacy
          .marketing_accepted
          ? true
          : false;
        this.callmeToSend.SASidentity =
          window.SASidentity == void 0 ? "UNDEFINED" : window.SASidentity;
        this.callmeToSend.requestType = "contatto";
        this.callmeToSend.topic = "RCN";
        this.callmeToSend.referrer = this.form.data.referrer;
        this.localToData(this.form.data.callme, this.callmeToSend);
        this.sendFol(this.callmeToSend, buttonName);
      } else {
        this.$refs.telefono.checkValue();
        /*this.$refs.quando.checkValue();*/
      }
    },
    sendOptionalClickHandler: function () {
      if (!this.form.state.sendOptional.disabled) {
        let buttonName = "sendOptional";
        this.requestToSend.SASidentity =
          window.SASidentity == void 0 ? "UNDEFINED" : window.SASidentity;
        this.requestToSend.requestType = "consulenza";
        this.requestToSend.topic = "RCN";
        this.requestToSend.referrer = this.form.data.referrer;
        let piva = /^\d{11}$/.test(this.form.data.request.optional.partita_iva)
          ? this.form.data.request.optional.partita_iva
          : null;
        let _rs = this.form.data.request.mandatory.ragione_sociale;
        let rs =
          _rs != null && _rs != "" && _rs != void 0
            ? encodeURIComponent(_rs)
            : null;
        if (piva != null) {
          this.form.state.sendOptional.disabled = true;
          this.getAziendaInfo(piva, rs)
            .then(() => { })
            .catch(() => { })
            .finally(() => {
              this.localToData(
                this.form.data.request.optional,
                this.requestToSend
              );
              this.sendFol(this.requestToSend, buttonName);
            });
        } else {
          this.localToData(this.form.data.request.optional, this.requestToSend);
          this.sendFol(this.requestToSend, buttonName);
        }
      }
    },
    optionalStateHandler: function (v) {
      this.form.data.request.optional[v.name] = v.value;
      this.checkValueOptionalState();
    },
    firstStateHandler: function (v) {
      this.form.data.request.mandatory.contatto = v.value;

      /* if(this.contatto(v.value))
                    this.form.state.disabledAhead = false
                else{
                    this.form.state.disabledAhead = true
                } */
      this.form.state.disabledAhead = this.$refs.contatto.checkValue();

      if (this.form.state.current == 1) this.checkValueSecondState();
    },
    secondStateHandler: function (v) {
      this.form.data.request.mandatory[v.name] =
        v.name == "provincia" ? v.value.id : v.value;
      this.checkValueSecondState();
    },
    privacyStateHandler: function (v) {
      this.form.data.privacy[v.name] = v.value;

      if (this.form.state.current == 1) this.checkValueSecondState();

      if (this.callYouSection) this.checkCallMe();
    },
    callMeHandler: function (v) {
      //console.log(v);
      this.form.data.callme[v.name] = v.value;
      this.checkCallMe();
    },
    checkValueSecondState: function () {
      if (
        !this.form.state.disabledAhead &&
        checkValidValue(this.form.data.request.mandatory) &&
        this.form.data.request.mandatory.provincia != undefined &&
        this.form.data.request.mandatory.provincia != null &&
        checkValidValue(this.form.data.privacy.acknowledgment_accepted)
      ) {
        this.form.state.sendMandatory.disabled = false;
      }
      else {
        this.form.state.sendMandatory.disabled = true;
      }
    },
    checkValueOptionalState: function () {
      //const { referente, partita_iva, settore_merceologicoField, interested } = this.$refs;
      const { referente, partita_iva, settore_merceologicoField } = this.$refs;
      let noInterestSelected = false;
        /* interested.$refs.input_elettricita_e_gas[0].checked == false &&
        interested.$refs.input_mobilita_elettrica[0].checked == false &&
        interested.$refs.input_efficienza_energetica[0].checked == false; */


      if (partita_iva.value.length == 0) {
        let disableButton =
          referente.checkValue() ||
          (referente.checkValue() == false &&
            referente.value.length + partita_iva.value.length == 0 &&
            settore_merceologicoField.value.length == 0 &&
            noInterestSelected);
        this.form.state.sendOptional.disabled = disableButton;
      } else {
        if (/^\d{11}$/.test(partita_iva.value)) {
          checkVATCode(partita_iva.value)
            .then((isValid) => {
              this.form.state.sendOptional.disabled = !isValid;
              partita_iva.error = !isValid;
            })
            .catch(() => {
              this.form.state.sendOptional.disabled = false;
            });
        } else {
          this.form.state.sendOptional.disabled = true;
          partita_iva.error = true;
        }
      }
    },
    checkCallMe: function () {
      if (
        checkValidValue(this.form.data.callme) &&
        this.form.data.callme.quando != undefined &&
        !this.$refs.telefono.checkValue() &&
        checkValidValue(this.form.data.privacy.acknowledgment_accepted)
      ) {
        this.form.state.sendCallMe.disabled = false;
      }
      else {
        this.form.state.sendCallMe.disabled = true;
      }
    },
    makeid: function () {
      var text = "";
      var possible = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789";

      for (var i = 0; i < 7; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      // eslint-disable-next-line
      //console.log(text)
      return text.toUpperCase();
    },
    getSASidentity: function () {
      var SASID_try_counter = 0;
      var _sint = self.setInterval(() => {
        SASID_try_counter += 1;
        let SASIdentity = this.readSASid();
        if (SASIdentity != "UNDEFINED" || SASID_try_counter == 10) {
          self.clearInterval(_sint);
          window.SASidentity = SASIdentity;
          // eslint-disable-next-line
          console.log("SSID", SASIdentity);
        }
      }, 2e3);
    },
    readSASid: function () {
      let SASIdentity = "UNDEFINED";
      let key = "_SI_DID_1.69f24a174700010101d6cf73";
      let _c = document.cookie.split(";").filter(function (e) {
        return e.indexOf(key) > -1;
      });
      if (_c.length > 0) {
        SASIdentity = _c[0].split("=")[1];
      }
      return SASIdentity;
    },
    getAziendaInfo: async function (piva, rs) {
      let azInfo = await axios
        .get(
          process.env.VUE_APP_API_AZIENDAINFO + "?piva=" + piva + "&rs=" + rs,
          { withCredentials: true }
        )
        .then((response) => {
          return response.data;
        });
      //console.log(azInfo);
      if (azInfo.status.code.toLowerCase() == "ok") {
        let rd = azInfo.data;
        if (rd.id_azienda !== 0) {
          this.requestToSend.PORTO__idAzienda = rd.id_azienda;
          this.requestToSend.PORTO__ragioneSociale = rd.ragione_sociale;
          this.requestToSend.PORTO__stato = rd.stato;
          if (rd.consulente !== null) {
            this.requestToSend.PORTO__idConsulente =
              rd.consulente.id_consulente;
            this.requestToSend.PORTO__nomeConsulente = rd.consulente.nome;
            this.requestToSend.PORTO__cognomeConsulente = rd.consulente.cognome;
          }
        }
      }
    },
    getReferrer: function () {
      let _r = document.referrer != "" ? document.referrer : "UNDEFINED";
      _r = _r.replace(/https?:\/\//, "");
      return _r;
    },
    localToData: function (local, data) {
      const keys = Object.entries(local);
      for (const [key, value] of keys) {
        if (value != undefined && value != "" && value != null)
          if (key == "sezione") {
            let sectionValue = this.getSezione(value);
            if (sectionValue) data[key] = sectionValue;
          } else data[key] = value;
      }
    },
    getSezione: function (id) {
      const keys = Object.entries(sections);
      // eslint-disable-next-line
      for (const [key, item] of keys) {
        if (item.id == id) return item.value;
      }
    },
    resetZeroState: function () {
      if (this.$refs.contatto) {
        this.$refs.contatto.cleanData();
      }
      this.form.state.disabledAhead = true;
    },
    resetFirstState: function () {
      this.resetField("ragione_sociale");
      //this.resetField("provincia");
      this.$refs.provinciaField.reset();

      this.resetField("form_acknowledgment_accepted");
      this.resetField("form_marketing_accepted");

      this.form.state.sendMandatory.disabled = true;
    },
    resetSecondState: function () {
      this.resetField("referente");
      //this.resetField("settore_merceologico");
      this.$refs.settore_merceologicoField.reset();
      this.resetField("partita_iva");
      this.resetField("interested");
    },
    resetCallMe: function () {
      this.resetField("telefono");
      //this.resetField("quando");
      this.$refs.quandoField.reset();
      this.resetField("call_acknowledgment_accepted");
      this.resetField("call_marketing_accepted");

      this.form.state.sendCallMe.disabled = true;
    },
    resetPrivacy: function () {
      this.form.data.privacy.acknowledgment_accepted = null;
      this.form.data.privacy.marketing_accepted = null;
    },
    resetAll: function (type) {
      this.resetZeroState();
      this.resetFirstState();
      this.resetSecondState();
      this.resetCallMe();

      //this.cleanObj(this.requestToSend)
      if (type == "request") {
        this.cleanRequestToSend();
      } else if (type == "call") {
        this.cleanCallmeToSend();
      } else {
        this.cleanRequestToSend();
        this.cleanCallmeToSend();
      }
      self.setTimeout(() => {
        this.dispatchFormIsActive(false);
      }, 5e1);
      self.setTimeout(() => {
        this.dispatchFormShape(
          this.form.state.current == 2 ? 1 : this.form.state.current
        );
      }, 1e3);
    },

    resetField: function (_n) {
      //console.log(_n);
      let el = this.$refs[_n];
      if (el) el.cleanData();
    },
    newId: function () {
      this.requestToSend.id = this.makeid();
      this.callmeToSend.id = this.makeid();
    },
    openFormHandler: function () {
      this.$emit("toggleTabletForm");
    },
    toggleForm: function () {
      this.fullscreeForm = !this.fullscreeForm;
      const { callme } = this.$refs;

      if (!this.fullscreeForm) {
        this.form.state.showResult = false;
        this.form.state.current = 0;
        this.callYouSection = false;

        if (callme) callme.close();

        this.resetAll();

        clearTimeout(this.timeoutTimer);
        clearTimeout(this.timeoutSending);
      }
    },
    startingButton: function (_n) {
      if (!this.form.state[_n].disabled) {
        this.form.state[_n].progressBar = true;
        this.form.state[_n].progressValue = 0.8;
      }
    },
    completeButton: function (_n) {
      this.form.state[_n].progressValue = 1;
    },
    errorButton: function (_n) {
      let btn = this.$refs[_n];
      if (btn) btn.errorHandler();

      this.resetButton(_n);
    },
    resetButton: function (_n) {
      setTimeout(() => {
        let btn = this.$refs[_n];
        if (btn) btn.resetStatus();

        this.form.state[_n].progressBar = false;
        this.form.state[_n].progressValue = 0;
      }, 600);
    },
    sendFol: function (body, btn) {
      //console.log(`FOL calling; payload:`, body);
      if (!this.form.state[btn].progressBar) {
        var self = this;
        const CancelToken = axios.CancelToken;

        self.startingButton(btn);

        self.timeoutTimer = setTimeout(() => {
          if (self.cancelRequest) self.cancelRequest();
        }, 10000);

        withProductionEnv(function () {
          let datafol = new FormData();
          self.json2formData(body, datafol);

          axios
            .post(process.env.VUE_APP_POST_FOL, datafol, {
              cancelToken: new CancelToken(function executor(c) {
                self.cancelRequest = c;
              }),
            })
            .then(function () {
              // eslint-disable-next-line
              self.folSuccess(btn);
            })
            .catch(function () {
              self.folError(btn);
            });
        });

        withDevelopmentEnv(function () {
          const dataToSend = Object.entries(body);
          console.log("fake FOL submission: ", dataToSend);
          self.folSuccess(btn);
        });
      }
    },
    json2formData: function (json, FormData) {
      for (var key in json) {
        FormData.append(key, json[key]);
      }
    },
    folSuccess: function (_btn) {
      this.callingSas(_btn);
      switch (_btn) {
        case "sendMandatory":
          this.tracking("sendMandatory");
          break;
        case "sendOptional":
          this.tracking("sendOptional");
          break;
        case "sendCallMe":
          this.tracking("callmeback");
          break;
        default:
          break;
      }
      this.completeButton(_btn);
      if (this.timeoutTimer) {
        clearTimeout(this.timeoutTimer);
      }
    },
    folError: function (_btn) {
      setTimeout(() => this.errorButton(_btn), 0);
    },
    callingSas: function (_label) {
      var _objMandatory = this.cloneObj(this.form.data.request.mandatory);
      var _objOptional = this.cloneObj(this.form.data.request.optional);
      var _objPrivacy = this.cloneObj(this.form.data.privacy);
      var _objCallme = this.cloneObj(this.form.data.callme);

      var obj = {};
      switch (_label) {
        case "sendMandatory":
          obj = Object.assign(_objMandatory, _objPrivacy);
          this.sasForm("LEAD_BOTTONEINVIA_RCN", obj);
          break;
        case "sendOptional":
          obj = _objOptional;
          this.sasForm("LEAD2_BOTTONEINVIA_RCN", obj);
          break;
        case "sendCallMe":
          obj = Object.assign(_objCallme, _objPrivacy);
          this.sasForm("CALLMEBACK_RCN", obj);
          break;
        default:
          break;
      }
    },
    updateState: function (_n) {
      setTimeout(() => {
        let _temp = this.form.state.current;
        switch (_n) {
          case "sendMandatory":
            this.form.state.current = 2;
            break;
          case "sendOptional":
            this.form.state.current = 3;
            //this.closeFullScreenOnScroll('sendOptional')
            this.timeoutSending = setTimeout(() => {
              this.afterDataSent();
            }, 2000);
            break;
          case "sendCallMe":
            this.form.state.showResult = true;
            //this.closeFullScreenOnScroll('sendCallMe')
            this.timeoutSending = setTimeout(() => {
              this.callMeResult();
            }, 2000);
            break;
          default:
            this.form.state.current = _temp;
            break;
        }
        this.resetButton(_n);
      }, 1000);
    },
    afterDataSent: function () {
      this.form.state.current = 0;
      this.form.state.showResult = false;
      this.resetAll("request");
      //this.cleanRequestToSend()

      if (
        this.mq.current == "mobile" ||
        this.mq.current == "tabletPortrait" ||
        this.mq.current == "tabletLandscapeSmall"
      )
        this.closeFullScreen();
    },
    callMeResult: function () {
      const { callme } = this.$refs;

      if (callme) callme.close();

      this.changeFormHandler();
      this.resetAll("call");
      //this.cleanCallmeToSend()

      if (
        this.mq.current == "mobile" ||
        this.mq.current == "tabletPortrait" ||
        this.mq.current == "tabletLandscapeSmall"
      )
        this.closeFullScreen();

      this.form.state.showResult = false;
    },
    closeFullScreen: function () {
      if (this.mq.current == "mobile" || viewportHeight() <= 560) {
        EventBus.emit("fixed-body");
      }
      this.$emit("toggleTabletForm");
    },
    closeFullScreenOnScroll: function (t) {
      if (
        (this.mq.current == "tabletPortrait" ||
          this.mq.current == "tabletLandscapeSmall") &&
        viewportHeight() > 560
      ) {
        if (t == "sendCallMe")
          window.addEventListener("scroll", this.scrollHandlerCallMe);
        else if (t == "sendOptional")
          window.addEventListener("scroll", this.scrollHandlerOptional);
      }
    },
    scrollHandlerCallMe: function () {
      if (this.fullscreeForm) {
        this.callMeResult();
        clearInterval(this.timeoutSending);
      }
      window.removeEventListener("scroll", this.scrollHandlerCallMe);
    },
    scrollHandlerOptional: function () {
      if (this.fullscreeForm) {
        this.afterDataSent();
        clearInterval(this.timeoutSending);
      }
      window.removeEventListener("scroll", this.scrollHandlerOptional);
    },
    printData: function (p) {
      // eslint-disable-next-line
      console.log(p);
    },
    cleanRequestToSend: function () {
      this.cleanObj(this.requestToSend);
      this.requestToSend.folID = 104;
      this.requestToSend.id = this.makeid();
    },
    cleanCallmeToSend: function () {
      this.cleanObj(this.callmeToSend);
      this.callmeToSend.folID = 104;
      this.callmeToSend.id = this.makeid();
    },
    cleanObj: function (obj) {
      var keys = Object.keys(obj);
      for (var i = 0; i < keys.length; i++) {
        delete obj[keys[i]];
      }
    },
    contatto: contattoHelper,
    telefono: telefonoHelper,
    tracking: tracking,
    sasForm: sasForm,
    cloneObj: cloneObj,
    sasClick: sasClick,
  },
};
</script>

<style></style>