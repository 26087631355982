<template>
  <div class="central-absolute">
    <div class="sticky-anchor">
      <div id="stickyContainer" class="desktop-sticky" ref="stickyContainer">
        <div
          :class="{
            sticky: true,
            short: formShape === 0,
            medium: formShape === 1,
            tall: formShape > 1,
            isActive: isFormActive,
            isInactive: !isFormActive,
          }"
          ref="formMainContainer"
          @click="dispatchFormActive(true)"
          :style="[{ top: offsetTop + 'px' }, style]"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ScrollMagic from 'scrollmagic'
//import 'ScrollMagicGSAP'
//import 'ScrollMagicIndicators'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  viewportScroll,
  viewportHeight,
  dispatchFormActive,
} from "../../helpers/helper.js";
import { EventBus } from "../../helpers/event-bus.js";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Sticky",
  data: () => ({
    state: null,
    scrollMagicScene: null,
    heroContent: null,
    offsetTop: 0,
    elementHeight: 580,
    marginBottom: 40,
    style: {},
    controller: null,
    scenePositionAbsolute: "top",
    formShape: 0,
    isFormActive: false,
  }),
  created: function () {
    EventBus.on("current-form-shape", (_s) => {
      {
        /* if (_s > 0) {
                    this.isFormExpanded = true;
                } else {
                    this.isFormExpanded = false;
                } */
       //console.log(`this.formShape: ${_s}`);
        this.formShape = _s;
      }
    });
    EventBus.on("current-form-isactive", (_a) => {
      setTimeout(() => {
        this.isFormActive = _a;
      }, 1e2);
    });
  },
  mounted: function () {
    /*
            let stickyContainer = document.getElementById('stickyContainer');
            
            this.controller = new ScrollMagic.Controller()

            this.scrollMagicScene = new ScrollMagic.Scene({ 
                        triggerElement: stickyContainer, 
                        triggerHook: "onLeave"
            })
            .on('enter', this.fixed)
            .on('leave', () => this.absolute())
            .on('end', () => this.updatePosition('end'))
            .on('start', () => this.updatePosition('start'))
            .addTo(this.controller) 
    */

    /* ############ GSAP ######### */

    this.scrollMagicScene = ScrollTrigger.create({
      //markers: true,
      trigger: "#stickyContainer",
      start: () => {
        this.updatePosition("start");
      },
      end: () => {
        this.updatePosition("end");
      },
      onEnter: () => {
        this.fixed();
        //console.log('onEnter');
      },
      onLeave: () => {
        this.absolute();
        //console.log('onLeave');
      }/* ,
      onToggle: (self) => console.log("toggled, isActive:", self.isActive), */
    });


    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
    this.controller = this.controller.destroy(true);
  },
  updated: function () {
    if (this.offsetTop < 0) {
      this.offsetHandler();
    }
  },
  methods: {
    updatePosition: function (_pos) {
      if (_pos == "start") {
        this.scenePositionAbsolute = "top";
        return "top top";
      } else if (_pos == "end") {
        this.scenePositionAbsolute = "bottom";
        return "bottom bottom";
      }
    },
    resizeHandler: function () {
      this.heroContent = document.getElementById("heroContent");
      this.offsetTop = this.heroContent.offsetTop;

      this.updateDuration();
      this.updateOffset();

      //console.log("this.state: ", this.state);
       
        if (this.state == "fixed") {
            this.fixed();
        } else {
            this.absolute();
        } 
      
    },
    updateDuration: function () {
      /* setTimeout(() => {
        let stickyContainer = document.getElementById("stickyContainer");

        if (stickyContainer) {
 
                let height = stickyContainer.offsetHeight;
                let delta = (document.getElementById('mobilita_elettrica_section').clientHeight - this.elementHeight) / 2;
                this.scrollMagicScene.duration(height - this.elementHeight - this.offsetTop - delta);

        }
      }, 50); */
    },
    offsetHandler: function () {
      this.offsetTop = this.heroContent.offsetTop;
        /* 
            let stickyContainer = document.getElementById("stickyContainer");
            let height = stickyContainer.offsetHeight;
            let delta = (document.getElementById('mobilita_elettrica_section').clientHeight - this.elementHeight) / 2;
            this.scrollMagicScene.duration(height - this.elementHeight - this.offsetTop - delta ) 
        */
    },
    fixed: function () {

      let stickyContainer = document.getElementById("stickyContainer");
      if (!this.$store.getters.getGlobalFullScreen && stickyContainer != null) {
        this.heroContent = document.getElementById("heroContent");
        var top = this.heroContent.offsetTop;
        
        let leftValue = stickyContainer.getBoundingClientRect().left;
        //this.offsetTop = top - this.scrollMagicScene.offset()
        //temp ema
        this.offsetTop = top - 0;

        this.state = "fixed";

        this.style = {
          position: "fixed",
          left: leftValue + "px",
          top: this.offsetTop + "px",
        };
      }
    },
    absolute: function () {
       /*  console.log(
        `ABSOLUTE document.getElementById("stickyContainer"): ${document.getElementById("stickyContainer")}`
      ); */
      let stickyContainer = document.getElementById("stickyContainer");
      if (viewportScroll() > 0 && !this.$store.getters.getGlobalFullScreen && stickyContainer != null) {
        this.heroContent = document.getElementById("heroContent");
        var top = this.heroContent.offsetTop;

        if (this.scenePositionAbsolute == "bottom") {
          
          let delta =
            (document.getElementById("mobilita_elettrica_section").clientHeight -
              this.elementHeight) /
            2;
          let height = stickyContainer.offsetHeight - delta;
          top = height - this.elementHeight;
        }

        this.state = "absolute";

        this.style = {
          position: "absolute",
          top: viewportScroll() < 0 ? "0px" : top + "px",
        };
        this.updateOffset();
      }
    },
    updateOffset: function () {
      this.heroContent = document.getElementById("heroContent");
      var top = this.heroContent.offsetTop;

      let difference = top + this.elementHeight - viewportHeight() + 60;

      if (difference > 0) {
        if (this.scenePositionAbsolute == "bottom") {
          return;
          //this.scrollMagicScene.offset(20)
        } else {
          return;
          //this.scrollMagicScene.offset(difference)
        }
      } else {
        return;
        //this.scrollMagicScene.offset(0)
      }
    },
    dispatchFormActive: dispatchFormActive,
  },
};
</script>