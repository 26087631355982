<template>
  <div
    id="mobileSticky"
    class="mobile-sticky"
    :class="[
      isSticky ? 'fixed' : '',
      bottom && !isSticky ? 'bottom' : 'top',
      open ? 'open' : '',
    ]"
    :style="styleFullScreen"
  >
    <div class="modal-close sticky stickyForm" @click="closeFullScreen" v-if="open">
      <img :src="closeIcon" alt="close" class="icon-x standard" />
    </div>
    <slot></slot>
  </div>
</template>
                                    


<script>
import VueScrollTo from "vue-scrollto";
/* import ScrollMagic from 'scrollmagic'
    import 'ScrollMagicGSAP'
    import 'ScrollMagicIndicators' */
import { EventBus } from "../../helpers/event-bus.js";
import { viewportScroll, viewportHeight } from "../../helpers/helper.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  inject: ["mq"],
  name: "MobileSticky",
  data: () => ({
    scrollMagicScene: null,
    controller: null,
    isSticky: false,
    bottom: false,
    stickyHeight: 115,
    small: 115,
    medium: 140,
    large: 560,
    open: false,
    scrollOptions: {},
    styleFullScreen: {},
    closeIcon: require("@/assets/img/ico-func-dark-x.svg"),
    passingBreakpoint: false,
  }),
  mounted: function () {
    this.scrollOptions = {
      container: "body",
      easing: "ease-in",
      offset: 0,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true,
    };

    /* let pointer = document.getElementById('mobilePointer')
            this.controller = new ScrollMagic.Controller()
            this.scrollMagicScene = new ScrollMagic.Scene({
                    triggerElement: pointer,
                    triggerHook: 'onEnter'
                })
                //.addIndicators()
                .on('enter', this.fixedMobileSticky)
                .on('start', this.bottomPosition)
                .on('leave', this.relativeMobileSticky)
                .addTo(this.controller) 
    */

    /* GSAP */
    let tween = gsap.to(".mobile-sticky", {y:5});
    this.scrollMagicScene = ScrollTrigger.create({
      //markers: true,
      animation: tween,
      trigger: '.sections',//"#mobilePointer",
      start: () => {
        return 'top top';
      },
      end: ()=> {
        return 'bottom bottom';
      },
      onEnter: () => {    
        this.fixedMobileSticky();
        //console.log("onEnter");
      },
      onLeave: () => {
        this.relativeMobileSticky();
        //console.log("onLeave");
      },
      onEnterBack: () => {    
        this.fixedMobileSticky();
        //console.log("onEnterBack");
      },
      onLeaveBack: () => {    
        this.relativeMobileSticky();
        //console.log("onLeaveBack");
      },
      onToggle: (self) => {
        if (self.isActive) {
          this.setBottomPosition(true);
        }
      }
    });

    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
    this.controller = this.controller.destroy(true);
  },
  methods: {
    getScrollEnd: function() {
      let el = document.getElementById('homepageSections');
      console.log(el.offsetHeight);
      return el.offsetHeight;
    },
    resizeHandler: function () {
      if (
        (this.mq.current == "tabletLandscapeSmall" ||
          this.mq.current == "tabletPortrait") &&
        viewportHeight() > 560
      ) {
        if (this.open) {
          this.stickyHeight = this.large;
          if (document.body.classList.contains("modal-open")) {
            EventBus.emit("fixed-body");
            this.passingBreakpoint = true;
          }
        } else {
          this.stickyHeight =
            this.mq.current == "tabletLandscapeSmall"
              ? this.medium
              : this.small;
        }
      } else {
        if (this.open && !document.body.classList.contains("modal-open")) {
          this.setFullScreen();
        }
        this.stickyHeight = this.open
          ? Math.min(
              Math.max(window.innerHeight, document.body.clientHeight),
              570
            )
          : this.small;
      }

      if (
        (this.mq.current != "mobile" && !(viewportHeight() <= 560)) ||
        !this.open ||
        this.passingBreakpoint
      ) {
        //this.scrollMagicScene.offset(this.stickyHeight)
        this.updateDuration();
        this.passingBreakpoint = false;
        this.styleFullScreen = {};
      }
    },
    updateDuration: function () {
      setTimeout(() => {
        //let container = document.getElementById('bodyContainer')
        //let containerHeight = container.clientHeight - this.stickyHeight
        //this.scrollMagicScene.duration(containerHeight)
      }, 50);
    },
    fixedMobileSticky: function () {
      this.isSticky = true;
      //console.log("called fixedMobileSticky.. this.isSticky:", this.isSticky);
    },
    relativeMobileSticky: function () {
      this.isSticky = false;
    },
    bottomPosition: function () {
      this.bottom = !this.bottom;
    },
    setBottomPosition: function(b) {
      this.bottom = b;
    },
    toggleForm: function () {
      this.open = !this.open;

      if (this.open) {
        let position = viewportScroll();
        let height = Math.min(
          Math.max(window.innerHeight, document.body.clientHeight),
          570
        );

        if (
          this.mq.current === "mobile" ||
          this.mq.current === "tabletPortrait" ||
          this.mq.current === "tabletLandscapeSmall"
        ) {
          this.stickyHeight =
            this.mq.current === "mobile" ? height : this.large;

          if (
            position < this.stickyHeight &&
            this.mq.current != "mobile" &&
            viewportHeight() > 560
          ) {
            this.scrollOptions.offset = -(viewportHeight() - this.stickyHeight);
            this.scrollOptions.onDone = this.setFullScreen;
            VueScrollTo.scrollTo("#mobilePointer", 500, this.scrollOptions);
          } else {
            this.setFullScreen();
          }
        }
      } else {
        if (this.mq.current == "tabletLandscapeSmall") {
          this.stickyHeight = this.medium;
        } else {
          this.stickyHeight = this.small;
        }
        this.styleFullScreen = {};
      }

      this.resizeHandler();
    },
    setFullScreen: function () {
      if (this.mq.current === "mobile" || viewportHeight() <= 560) {
        this.styleFullScreen = {
          position: "fixed",
          bottom: "0",
          left: "0",
          transform: "translate(0px, 0px) !important"
        };
        EventBus.emit("fixed-body");
        document.body.style.top = "0px";
      }
    },
    closeFullScreen: function () {
      if (this.mq.current == "mobile" || viewportHeight() <= 560) {
        EventBus.emit("fixed-body");
        this.$emit("toggleTabletForm");
      } else this.$emit("toggleTabletForm");
    },
  },
};
</script>

<style scoped>
</style>