<template>
  <!--md-dialog 
        ref="dialog"
        :md-active.sync="showModal" 
        @md-opened="opened"
        @md-closed="closed"
        :md-fullscreen="false">
            <div 
                class="modal-close"
                @click="close">
                    <img :src="closeIcon" alt="close" class="icon-x standard">
                    <img :src="closeCircleIcon" alt="close" class="icon-x circle">
                </div>
            <md-dialog-content 
                id="mdl">
                <modal-privacy v-if="type === 'privacy'" />
                <modal-cookie v-if="type === 'cookie'" />
            </md-dialog-content>
        </md-dialog-->

  <v-dialog v-model="showModal" width="auto">
    <v-card class="md-dialog">
      <v-card-text class="md-dialog-content">
        <div 
                class="modal-close"
                @click="close">
                    <img :src="closeIcon" alt="close" class="icon-x standard">
                    <img :src="closeCircleIcon" alt="close" class="icon-x circle">
                </div>
        <modal-privacy v-if="type === 'privacy'" />
        <modal-cookie v-if="type === 'cookie'" />
      </v-card-text>
      <v-card-actions>
        <v-btn class="customButton" color="primary" block @click="close">CHIUDI</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import text from "../../assets/text/text";
import VueScrollTo from "vue-scrollto";
import { EventBus } from "../../helpers/event-bus.js";

import ModalPrivacy from '../Modals/ModalPrivacy'
import ModalCookie from '../Modals/ModalCookie'

export default {
  name: "ReModal",
  components: {
    ModalPrivacy,
    ModalCookie
  },
  props: {
    type: String,
    scrollto: String,
  },
  data: () => ({
    showModal: false,
    text: text,
    scrollOptions: {},
    isFullscreen: false,
    closeIcon: require("@/assets/img/ico-func-dark-x.svg"),
    closeCircleIcon: require("@/assets/img/ico-func-white-x-circle.svg"),
  }),
  mounted: function () {
    this.scrollOptions = {
      container: "#mdl",
      easing: "ease-in",
      offset: 0,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true,
    };
  },
  methods: {
    textSwitch: function (t) {
      // eslint-disable-next-line
      console.log(t);
    },
    openModal: function () {
      this.showModal = true;
    },
    opened: function () {
      this.isFullscreen = document.body.classList.contains("modal-open");
      if (!this.isFullscreen) EventBus.emit("fixed-body");

      if (this.scrollto != null) {
        VueScrollTo.scrollTo("#" + this.scrollto, 800, this.scrollOptions);
      }
    },
    closed: function () {
      if (!this.isFullscreen) EventBus.emit("fixed-body");
    },
    close: function () {
      /* const { dialog } = this.$refs;
      if (dialog) dialog.closeDialog(); */
      this.showModal = false;
    },
  },
};
</script>