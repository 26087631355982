<template>
  <re-slick
    v-if="mq.current === 'mobile'"
    :type="type"
    :data="text"
    :active="activeElement"
  ></re-slick>

  <div class="box" :class="type" v-else>
    <div class="header">
      <div
        v-for="(item, index) in text"
        v-bind:key="index"
        class="menu-item"
        :id="item.id"
        :class="index == active ? 'active' : ''"
        @click="selectHandler(index, item.id)"
      >
        <p
          :class="[
            isEfficienzaSection ? 'medium-title-crowded' : 'medium-title',
          ]"
        >
          {{ item.menu }}
        </p>
      </div>
    </div>

    <div class="body">
      <transition-group :name="animationType" tag="div">
        <div class="content" v-bind:key="'content_' + filteredList.title">
          <div class="content-description">
            <img
              :src="require('@/assets/img/' + filteredList.icon)"
              :alt="filteredList.title"
              class="re-ico icon-section" :class="filteredList.image"
            />

            <h5
              v-if="
                filteredList.hasOwnProperty('headline') &&
                filteredList.headline != null
              "
              class="headline"
              v-html="filteredList.headline"
            ></h5>
            <h4 class="title" v-html="filteredList.title"></h4>
            <p
              class="standard-light-text" :class="filteredList.image+'SectionTxt'"
              v-html="filteredList.description"
            ></p>
            <div class="sectionButtonContainer" v-for="(bt, i) in filteredList.button" v-bind:key="i">
              <img alt="Download" :src="require('@/assets/img/ico-download-gray.svg')" class="dwl-ico" />
              <a
                class="btn btn-medium"
                target="_blank"
                :href="filteredList.button[i].href"
                :id="filteredList.button[i].id"
                >{{ filteredList.button[i].label }}</a
              >
            </div>
              <div class="appDwl-frame" v-if="filteredList.image == 'ineapp'">
                <a id="btn__quinta_appdownload" class="appDwl-badge" :class="{hideQRCode: (mq.current != 'desktop' && mq.current != 'bigDesktop')}" href="https://go.repower.com/app/ra" target="_blank"></a>
                <div class="appDwl-txt"><p>Scarica l’app <b>Recharge Around</b><br />dal tuo store preferito</p></div>
              </div>
          </div>
          <div
            class="content-image"
            :class="filteredList.image"
            :style="{
              backgroundImage: 'url(' + images[filteredList.image] + ')',
            }"
          ></div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import text from "../../assets/text/text";
import ReSlick from "../ReSlick/ReSlick";

import { sasClick } from "../../helpers/tracking";

export default {
  inject: ["mq"],
  name: "SectionBox",
  components: {
    ReSlick,
  },
  props: {
    type: String,
    activeElement: Number,
  },
  data: () => ({
    isEfficienzaSection: function () {
      return this.type == "efficienza";
    },
    text: {},
    active: 0,
    animationType: "change-content-right",
    images: {
      takeeway: require("@/assets/img/takeeway.jpg"),
      ricarichegreen: require("@/assets/img/ricarichegreen.jpg"),
      fattistrada: require("@/assets/img/fattistrada.jpg"),
      lookefeel: require("@/assets/img/lookefeel.jpg"),
      ineapp: require("@/assets/img/ineapp.jpg"),
      chiaviinmano: require("@/assets/img/chiaviinmano.jpg"),
    },
  }),
  created: function () {
    this.getText(this.type);
  },
  methods: {
    getText: function (_t) {
      this.text = text.sections[_t].box.items;
    },
    selectHandler: function (i, _id) {
      if (i > this.active) this.animationType = "change-content-right";
      else if (i < this.active) {
        this.animationType = "change-content-left";
      }
      this.active = i;
      sasClick(_id);
    },
  },
  watch: {
    activeElement: function () {
      this.active = this.activeElement;
    },
  },
  computed: {
    filteredList: function () {
      return this.text[this.active];
    },
  },
};
</script>