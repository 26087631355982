<template>
    <footer>
        <div class="footer-container">
            
                <img src="~@/assets/img/repower-logo-footer.svg" alt="Repower"/>
                <div class="menu">
                    <a 
                        class="btn btn-medium lightGrey _cookieExempted"
                        v-for="item in text.menu"
                        :key="item.name"
                        :id="item.type"
                        @click="openModal(item.type)">{{item.name}}</a>

                        <a href="#" class="cky-banner-element btn btn-medium lightGrey">Configura cookie</a>
                </div>

                <div class="info">
                    <p 
                        class="medium-text lightGrey"
                        v-html="text.info"></p>
                </div>

            
        </div>
    </footer>
</template>

<script>
    import text from '../../assets/text/text'
    import { EventBus } from '../../helpers/event-bus.js';

    export default {
        name: 'ReFooter',
        data: () => ({
            text: text.footer
        }),
        methods: {
            openModal(v){
                EventBus.emit('show-modal', {type: v, id:null});
            }
        },
        
    }
</script>
