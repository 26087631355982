<template>
    <swiper-container :id="'SWP-' + type" :init="false" :grid-rows="1" :slides-per-view="1.18" :autoHeight="false"
        :centered-slides="true" :class="type" @slidechange="traceSwipe"
        class="slick-slider sections-slider">
        <swiper-slide class="item slide" :id="item.id" :key="index + '_content'" v-for="(item, index) in data">
            <div class="content">
                <div class="header">
                    <p class="medium-title">{{ item.menu }}</p>
                </div>
                <div class="body">
                    <img :src="require('@/assets/img/' + item.icon)" alt="" class="re-ico icon-section" :class="item.image" />
                    <h4 class="title" v-html="item.title"></h4>

                    <p class="standard-light-text" v-html="item.description"></p>
                    <div class="sectionButtonContainer" v-for="(bt, i) in item.button" v-bind:key="i">
                        <img alt="Download" :src="require('@/assets/img/ico-download-gray.svg')" class="dwl-ico" />
                        <a class="btn btn-medium" target="_blank" :href="item.button[i].href" :id="item.button[i].id">{{
                            item.button[i].label }}</a>
                    </div>
                    <div class="appDwl-frame" v-if="item.image == 'ineapp'">
              <a id="btn__quinta_appdownload" class="appDwl-badge" :class="{hideQRCode: (mq.current != 'desktop' && mq.current != 'bigDesktop')}" href="https://go.repower.com/app/ra" target="_blank"></a>
              <div class="appDwl-txt"><p>Scarica l’app <b>Recharge Around</b><br />dal tuo store preferito</p></div>
            </div>
                </div>

            </div>
        </swiper-slide>
    </swiper-container>
    <!--/slick-->
</template>

<script>
import { sasClick } from '../../helpers/tracking';
import { register } from 'swiper/element/bundle';
register();



export default {
    inject: ["mq"],
    name: 'ReSlick',
    components: {
    },
    props: ['data', 'type', 'active'],
    created: function () {

    },
    data: () => ({
        swp: undefined
    }),
    methods: {
        initSwiper() {
            this.swp = document.getElementById(`SWP-${this.type}`);
            //console.log(this.swp);
            this.swp.initialize();
        },
        traceSwipe(ev) {
            const [vipera] = ev.detail;
            console.log(vipera.slides[vipera.realIndex].id);
            if (
                vipera != undefined &&
                vipera.realIndex != undefined &&
                Array.isArray(vipera.slides) &&
                vipera.slides[vipera.realIndex].id != undefined
            ) {
                sasClick(vipera.slides[vipera.realIndex].id);
            }
        }
    },
    mounted: function () {
        this.initSwiper();
        if (this.active != 0) {
            //this.$refs.slick.goTo(this.active)
            this.swp.slideTo(this.active, 250, true);
        }
    }
}
</script>