<template>
    <div :style="styleContainer">
        
        <img 
            v-if="hideImg"
            :src="srcImage"
            :style="styleImg"
            v-on:load="loaded"
            v-on:error="error"/>

        <div 
            class="img"
            :style="{backgroundImage: 'url('+ srcImage +')', backgroundPosition: position }"></div>

    </div>
</template>

<script>
    export default {
        name: 'LoadingImage',
        props: {
            srcImage: String,
            position: String
        },
        data: () => ({
            styleContainer: {
                height: '100%',
                width: '100%'
            },
            styleImg: {
                opacity: '0',
                position: 'absolute',
                zIndex: '-999',
                height: '1px',
                width: '1px'
            },
            hideImg: true
        }),
        methods: {
            loaded: function(){
                this.$emit('load')
                this.hideImg = false
            },
            error: function(){
                this.$emit('error')
                this.hideImg = false
            }
        }
    }
</script>
