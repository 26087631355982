<template>
    <div 
        :id="type+'_content'"
        class="type_content">
        <div 
            :id="type+'_scroll'"
            class="--scroll"></div>
        <div 
            :id="type+'_box'"
            ref="box"
            class="section-box">
            <div class="section-title">
                <div class="line"></div>
                <h3 
                    class="title"
                    :class="type != 'mobilita_elettrica' ? 'white' : ''" 
                    v-html="text.title"></h3>
                <p 
                    class="standard-text description" 
                    :class="type != 'mobilita_elettrica' ? 'white' : ''"
                    v-html="text.description"></p>
            </div>
            <section-box 
                :type="type"
                :activeElement="activeElement"></section-box>
            <div 
                :id="type+'_pointer'"
                class="--pointer"
            ></div>
        </div>
        <div 
            :id="type+'_sticky'"
            class="--sticky"></div>
    </div>
</template>

<script>
    import text from '../../assets/text/text'
    import SectionBox from '../SectionBox/SectionBox'
    import { gsap, Linear } from 'gsap';
    

    //import { Linear } from 'gsap'
    /* import ScrollMagic from 'scrollmagic'
    import 'ScrollMagicGSAP'
    import 'ScrollMagicIndicators' */
    
    export default {
        inject: ['mq'],
        name: 'SectionArea',
        components: {
            SectionBox
        },
        props:{
            type: String,
            activeElement: Number
        },
        data: () => ({
            text: {},
            scene: null,
            fullScreen : false,
            tween: null
        }),
        mounted: function(){
            //var controller = new ScrollMagic.Controller()
            this.getText(this.type)
            const {box} = this.$refs
            //let pointer = document.getElementById(this.type+'_scroll')

            // eslint-disable-next-line
            this.tween = gsap.from(box, {y: 100, duration: 1, ease: Linear.easeOut})

            /* this.scene = new ScrollMagic.Scene({ triggerElement: pointer, triggerHook: 0.8})
                //.addIndicators()
                .setTween(this.tween)
                .addTo(controller)  */

            window.addEventListener('resize', this.resizeHandler)
            this.resizeHandler()
        },
        destroyed: function() {
            window.removeEventListener('resize', this.resizeHandler)
        },
        methods: {
            getText: function(_t){
                this.text = text.sections[_t]
            },
            resizeHandler: function (){
                //const { box } = this.$refs

                if(this.mq.current === 'mobile' || this.mq.current === 'tabletPortrait' || this.mq.current === 'tabletLandscapeSmall'){
                    //this.scene.duration(300)
                }else{
                    //this.scene.duration(box.clientHeight - 50)
                }
            }
        },
        computed: {
            isFullScreen: function(){
                return this.$store.getters.getGlobalFullScreen
            }
        },
        watch:{
            isFullScreen: function(val){
                if(val){
                    this.tween.pause()
                    /* console.log('isFullScreen: ', val)
                    return; */
                }else{
                    this.tween.play()
                    /* console.log('isFullScreen: ', val)
                    return; */
                }
            }
        }
    }
</script>

<style scoped>

</style>