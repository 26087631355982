<template>
    <section 
        :id="type+'_section'"
        ref="section"
        class="section body-section"
        :class="[type, displayContent, position]">
        <full-screen 
            class="section-background">
            <div 
                class="background-image hide"
                :class="[displayContainer, displayImage]"
                :style="[style]">
                
                <loading-image
                    :srcImage="getSectionImage"
                    v-on:load="loaded"
                    v-on:error="error"></loading-image>
               
            </div>
        </full-screen>
         <slot></slot>
    </section>
</template>

<script>
import text from '../../assets/text/text'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import FullScreen from '../FullScreen/FullScreen'
import LoadingImage from '../LoadingImage/LoadingImage'

export default {
    name: 'ViewSection',
    components: {
        FullScreen,
        LoadingImage
    },
    props: {
        type: String,
        position: String
    },
    data: () => ({
        displayContainer: '',
        displayImage: '',
        displayContent: '',
        widthImage: {},
        imgLoaded: false,
        text: text,
        scrollMagicScene: null,
        controller: null
    }),
    mounted: function() {

        const { section } = this.$refs
        this.scrollMagicScene = ScrollTrigger.create({
            //markers: true,
            trigger: section,
            start: () => {
                return "top bottom";
            },
            onEnter: () => {
                this.backgroundHandler();
            }
        });
        
    },
    methods: {
        backgroundHandler: function(){
            this.displayContainer = 'show'

            if(this.imgLoaded){
                this.showImage()
                this.scrollMagicScene.kill();
            }
        },
        loaded: function(){
            this.imgLoaded = true

            if(this.displayContainer == 'show'){
                this.showImage();
                this.scrollMagicScene.kill();
            }
        },
        error: function(){
            this.imgLoaded = true
            if(this.displayContainer == 'show')
                this.showImage()
        },
        showImage: function(){
            this.displayImage = 'show-image'
            this.displayContent = 'show-content'
        }
    },
    computed: {
        getSectionImage: function(){
            //console.log(require('@/assets/img/'+this.text.sections[this.type].img));
            return require('@/assets/img/'+this.text.sections[this.type].img);
        },
        style: function(){
            let _style= {}

            if(this.position == 'left')
                _style = { left: 0}
            else if(this.position == 'right')
                _style = { right: 0}

            return _style
        }
    },
}
</script>