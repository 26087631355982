<template>
    <div class="full--screen">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'FullScreen',
    }
</script>