<template>
    <div class="full-video" id="videoContainer">
            <a 
                class="btn btn-medium close-video white" 
                @click.stop="close">CHIUDI</a>

                <iframe id="fullVideo" width="560" height="315"
                    :src="playlist" 
                    allow="autoplay; fullscreen"
                    frameborder="0" 
                    allowfullscreen></iframe>
    </div>
</template>

<script>
    export default {
        name: 'FullVideo',
        data: () => ({
            //playlist: "https://www.youtube-nocookie.com/embed/?listType=playlist&list=PLt15maex9FIwEqj2E68bsvADfADXaUbpB&enablejsapi=1&controls=1"
            //playlist: "https://www.youtube-nocookie.com/embed/?listType=playlist&list=PLt15maex9FIx2M5nkvRK3i2r0AU6djXKl&enablejsapi=1&controls=1"
            playlist: "https://www.youtube-nocookie.com/embed/?listType=playlist&list=PLt15maex9FIwMYg25Rqr-NnfTHlhIcqr0&enablejsapi=1&controls=1"
        }),
        methods: {
            close: function(){
                this.$emit('openFullVideo')
            }
        }
    }
</script>