<template>
  <div>
    <div>
      <re-header v-bind:headerStyle="header"></re-header>
      <div class="sections">
        <!-- body -->
        <hero
          ref="hero"
          v-on:scrollTo="scrollToFirst"
          v-on:openFullVideo="videoHandler"
        ></hero>

        <mobile-sticky
          ref="mobileSticky"
          v-on:toggleTabletForm="fullFormHandler"
          v-if="
            this.mq.current === 'mobile' ||
            this.mq.current === 'tabletPortrait' ||
            this.mq.current === 'tabletLandscapeSmall'
          "
        >
          <re-form
            ref="reform"
            v-on:toggleTabletForm="fullFormHandler"
          ></re-form>
        </mobile-sticky>

        <sections id="homepageSections" ref="sections"></sections>

        <!-- sticky: mobile || desktop -->
        <sticky
          v-if="
            this.mq.current === 'tabletLandscapeLarge' ||
            this.mq.current === 'desktop' ||
            this.mq.current === 'bigDesktop'
          "
        >
          <re-form></re-form>
        </sticky>
      </div>

      <!--<cover
        v-if="
          this.mq.current == 'mobile' || this.mq.current == 'tabletPortrait'
        "
        ><cover-content></cover-content
      ></cover>-->
      <!--<slick-cover v-else
        ><cover-content contentClass="show"></cover-content
      ></slick-cover>-->

      <re-footer></re-footer>
    </div>

    <transition name="fade">
      <full-video @openFullVideo="videoHandler" v-show="fullVideo"></full-video>
    </transition>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";

import Sticky from "../Sticky/Sticky";
import MobileSticky from "../MobileSticky/MobileSticky";
import Sections from "../Sections/Sections";
import Hero from "../Hero/Hero";
import ReHeader from "../ReHeader/ReHeader";
import ReFooter from "../ReFooter/ReFooter";
//import Cover from "../Cover/Cover";
//import SlickCover from "../SlickCover/SlickCover";
//import CoverContent from "../CoverContent/CoverContent";
import ReForm from "../ReForm/ReForm";
import FullVideo from "../FullVideo/FullVideo";
import { EventBus } from "../../helpers/event-bus.js";
import { viewportHeight } from "../../helpers/helper.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  inject: ["mq"],
  name: "HomePage",
  components: {
    Sticky,
    MobileSticky,
    Sections,
    Hero,
    ReHeader,
    //Cover,
    ReForm,
    ReFooter,
    FullVideo,
    //SlickCover,
    //CoverContent,
  },
  data: () => ({
    header: "white",
    mobileSticky: false,
    mobileBottomPositionSticky: false,
    sceneHeader: {},
    showDialog: false,
    fullVideo: false,
    offsetMax: 272.5,
    options: {
      container: "body",
      easing: "ease-in",
      offset: -20,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true,
    },
  }),
  mounted: function () {
    //const controller = new ScrollMagic.Controller()
    const { sections } = this.$refs;
    let container = sections.$refs.container;


    //console.log(`###MQ### -> ${this.mq.current}`);
    let params = this.$route.params;

    if (this.mq.current == "mobile" || this.mq.current == "tabletPortrait")
      this.options.offset = -120;
    else {
      let offset =
        (viewportHeight() -
          document.getElementById("mobilita_elettrica").offsetHeight) /
        2;
      this.options.offset = offset > this.offsetMax ? -this.offsetMax : -offset;
    }

    /* this.sceneHeader = new ScrollMagic.Scene({ triggerElement: container, triggerHook: "onLeave"})
            //.addIndicators()
            .on('enter', this.colorHeader)
            .on('leave', this.whiteHeader)
            .addTo(controller) */

    /* GSAP */

    this.sceneHeader = ScrollTrigger.create({
      //markers: true,
      trigger: `#${container.id}`,
      start: () => {
        return "top 80%";
      },
      end: ()=> {
        return 'top top';
      },
      onEnter: () => {
        this.whiteHeader();
        //console.log("header onEnter");
      },
      onEnterBack: () => {
        this.whiteHeader();
        //console.log("header onEnterBack");
      },
      onLeave: () => {
        this.colorHeader();
        //console.log("header onLeave");
      }/* ,
      onToggle: (self) => console.log("toggled header scroll controller, Direction:", self.direction, `for ${container.id}`) */
    });

    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();

    let _id = this.isValidSection(params);

    if (_id)
      setTimeout(() =>
        VueScrollTo.scrollTo(_id.toLowerCase(), 1000, this.options, 500)
      );

    let _subsection = this.isValidSubsection(params);
    if (_subsection) EventBus.emit("subsection", _subsection);

    // eslint-disable-next-line
    youtubePlayer();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    isValidSection: function (_t) {
      if (_t.section) {
        let sections = [
          "energia_per_il_tuo_lavoro",
          "mobilita_elettrica",
          "efficienza_energetica",
        ];
        return sections.indexOf(_t.section.toLowerCase()) > -1
          ? "#" + _t.section
          : "";
      }
    },
    isValidSubsection: function (_t) {
      if (_t.subsection) {
        let subsections = {
          energia_per_il_tuo_lavoro: [
            "energia_che_ti_serve",
            "energia_per_la_tua_attivita",
            "energia_e_consulenza_per_le_pmi",
          ],
          mobilita_elettrica: [
            "flotta_elettrica",
            "strumenti_di_ricarica",
            "circuiti_di_ricarica",
          ],
          efficienza_energetica: ["vampa", "dioda", "effettiva", "focus"],
        };
        let _arr = subsections[_t.section];

        if (_arr) {
          return _arr.indexOf(_t.subsection.toLowerCase()) > -1
            ? { item: _t, index: _arr.indexOf(_t.subsection.toLowerCase()) }
            : false;
        }
      }
      return false;
    },
    colorHeader: function () {
      this.header = "color";
    },
    whiteHeader: function () {
      this.header = "white";
    },
    scrollToFirst: function () {
      VueScrollTo.scrollTo("#energia_per_il_tuo_lavoro", 800, this.options);
    },
    resizeHandler: function () {
      this.updateHeaderOffset();
    },
    updateHeaderOffset: function () {
      if (this.mq.current == "bigDesktop" || this.mq.current == "desktop") {
        return;
        //this.sceneHeader.offset(-40)
      } else {
        return;
        //this.sceneHeader.offset(0)
      }
    },
    fullFormHandler: function () {
      const { mobileSticky, sections, reform } = this.$refs;

      if (mobileSticky && sections && reform) {
        sections.toggleForm().then(() => {
          mobileSticky.toggleForm();
          reform.toggleForm();
        });
      }
    },
    videoHandler: function () {
      this.fullVideo = !this.fullVideo;
      /* eslint-disable */
      if (this.fullVideo) {
        if (player) {
          player.setLoop(true);
          player.playVideoAt(0);
        }
      } else {
        if (player) player.pauseVideo();
      }
      EventBus.emit("fixed-body");
    },
  },
};
</script>
