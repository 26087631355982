<template>
    <div>
        <label 
            class="r-checkbox-container">
            <input
                type="checkbox"
                v-model="value"
                @change="onchange">
            <span class="r-checkbox-checkmark">
                <img alt="Checkbox" :src="checked" class="r-checkbox-icon" />
            </span>
            <p 
                class="small-text privacy-text"
                 v-html="text"></p>
        </label>
    </div>
</template>

<script>
    import { EventBus } from '../../helpers/event-bus.js';
    export default {
        name: 'RCheckboxInput',
        props: {
            name: String,
            type: String,
            state: String,
            text: String
        },
        data: () => ({
            value: false,
            checked: require('@/assets/img/done.svg'),
        }),
        mounted: function(){
            let el = undefined
            let category = null
            let id = null
            switch(this.type){
                case 'acknowledgment':
                    el = document.getElementById(this.state+'_privacy')
                    category = 'privacy'
                    break;
                case 'marketing':
                    el = document.getElementById(this.state+'_privacy2B')
                    category = 'privacy'
                    id = 'PAR2B'
                    break;
                default:
                    break;
            }
            if(el)
                el.addEventListener('click', () => this.openModal(event, category, id))

        },
        methods: {
            onchange: function(){
                this.$emit('onchange', {name: this.name, value: this.value})
            },
            cleanData: function(){
                this.value = false
                this.$emit('onchange', {name: this.name, value: this.value})
            },
            openModal: function(e, _c, _i){
                e.preventDefault();
                e.stopPropagation();

                EventBus.emit('show-modal', {type:_c, id: _i});
            }
        }
    }
</script>
