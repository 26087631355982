<template>
    <div>
        
        <parallax>
            <div 
                id="bodyContainer"
                ref="container"
                class="sectionsContainer">
                
                <div 
                    class="offsetMobileticky"
                    :class="open ? 'open' : ''"></div>
                
                <view-section 
                    ref="firstElement"
                    type="elettricita_gas"
                    position="left">
                    <section-skeleton 
                        id="energia_per_il_tuo_lavoro">
                        <section-area 
                            type="elettricita_gas"
                            :activeElement="activeElettricitaGas"
                            ></section-area>
                    </section-skeleton>
                </view-section>
                
                <view-section 
                    type="mobilita_elettrica"
                    position="right">
                    <section-skeleton 
                        id="mobilita_elettrica">
                        <section-area 
                            type="mobilita_elettrica"
                            :activeElement="activeMobilitaElettrica"></section-area>
                    </section-skeleton>
                </view-section>
                
                <!-- <view-section 
                    type="efficienza"
                    position="left"
                    ref="lastElement">
                    <section-skeleton 
                        id="efficienza_energetica">
                        <section-area 
                            type="efficienza"
                            :activeElement="activeEfficienza"
                            ref="lastElementChild"></section-area>
                    </section-skeleton>
                </view-section> -->

                <div 
                    class="offsetMobileticky"
                    :class="open ? 'open' : ''"></div>
                
            </div>

        </parallax>
        
    </div>
</template>

<script>

    import ViewSection from '../ViewSection/ViewSection'
    import Parallax from "../Parallax/Parallax";
    import SectionSkeleton from '../SectionSkeleton/SectionSkeleton'
    import SectionArea from '../SectionArea/SectionArea'
    import { EventBus } from '../../helpers/event-bus.js'


    export default {
        name: 'Sections',
        components: {
            ViewSection,
            Parallax,
            SectionSkeleton,
            SectionArea
        },
        data: () => ({
            open: false,
            activeElettricitaGas: 0,
            activeMobilitaElettrica: 0,
            activeEfficienza: 0
        }),
        created: function(){
            EventBus.on('subsection', (_s) => {
                let item = _s.item.section
                switch(item){
                    case 'energia_per_il_tuo_lavoro':
                        this.activeElettricitaGas = _s.index
                        break;
                    case 'mobilita_elettrica':
                        this.activeMobilitaElettrica = _s.index
                        break;
                    /* case 'efficienza_energetica':
                        this.activeEfficienza = _s.index
                        break; */
                    default:
                        break;
                }
            })
        },
        methods: {
            toggleForm: function(){
                this.open = !this.open
                return new Promise((resolve) => {
                    resolve(true)
                })
            }
        }

    }
</script>

<style scoped>

</style>