<template>
    <header 
        :class="[
            animation, 
            typeHeader, 
            headerStyle]">
        <div class="header-container">
            <img class="logoRepower" 
                :src="headerStyle == 'white' ? whiteLogo : colorLogo" alt="Repower"/>

                <img class="logoRCN"
                :src="headerStyle == 'white' ? whiteRCN : colorRCN" alt="Repower Charging Net"/>
        </div>
    </header>
</template>

<script>
    import { 
        viewportScroll,
    } from '../../helpers/helper.js'

    export default {
        name: 'ReHeader',
        props:{
            headerStyle: String
        },
        data: () => ({
            lastScrollTop: 0,
            delta: 20,
            typeHeader: 'absolute',
            animation: '',
            whiteLogo: require('@/assets/img/repower-logo-white.svg'),
            colorLogo: require('@/assets/img/repower-logo.svg'),
            whiteRCN: require('@/assets/img/rcn-logo-white.svg'),
            colorRCN: require('@/assets/img/rcn-logo.svg'),
        }),
        mounted: function(){
            window.addEventListener('scroll', this.scrollHandlerContainer)
        },
        destroyed: function() {
            window.removeEventListener('scroll', this.scrollHandlerContainer)
        },
        methods: {
            scrollHandlerContainer: function(){
                if(!this.$store.getters.getGlobalFullScreen)
                    this.scrollHandler()
            },
            scrollHandler: function() {
                let newPosition = viewportScroll()

                if(Math.abs(this.lastScrollTop - newPosition) <= this.delta)
                    return;
                    
                if(newPosition > this.lastScrollTop){
                    if(this.typeHeader != 'absolute')
                        this.absoluteHeader()
                }else{
                    if(this.typeHeader != 'fixed')
                        this.fixedHeader()
                }
                    
                this.lastScrollTop = newPosition <= 0 ? 0 : newPosition;
                
            },
            absoluteHeader: function(){
                this.animation = 'up';
                setTimeout(this.setAbsolute, 250)
            },
            fixedHeader: function (){
                this.animation = 'down';
                this.setFixed()
            },
            setAbsolute: function (){
                this.typeHeader = 'absolute'
            },
            setFixed: function (){
                this.typeHeader = 'fixed' 
            }
        },
    }
</script>