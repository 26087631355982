export default {
    hero: {
        title: '<span style="font-family: TheMixBold">FAI BUSINESS</span><br />CON LA MOBILITÀ<br />ELETTRICA',
        description: 'Con Repower Charging Net basta un posto auto<br />per offrire a tutti il servizio di ricarica che ti fa guadagnare.',
        action: 'GET THE SPOT',
        playlist: {
            cover: {
                title: 'THAT’S THE POWER OF LOVE',
                subtitle: 'REPOWER'
            }
        }
    },
    form: {
        title: 'Scopri la soluzione giusta per te.',
        descriptionMandatory: 'Fatti contattare dal tuo consulente Repower.',
        descriptionOptional: 'Solo qualche informazione in più.<br> Sarà utile in fase di contatto.',
        result: {
            title: 'Grazie!',
            description: 'Ti contatteremo al più presto per individuare insieme a te la soluzione più adatta alle tue esigenze.'
        },
        fields: {
            contatto: {
                placeholder: "Telefono"
            },
            ragioneSociale: {
                placeholder: "Nome azienda"
            },
            provincia: {
                placeholder: "Provincia"
            },
            referente: {
                placeholder: "Persona da contattare"
            },
            settoreMerceologico: {
                placeholder: "Settore merceologico"
            },
            partitaIva: {
                placeholder: "Partita iva"
            },
            interesse: {
                placeholder: "SONO INTERESSATO A",
                options: [{ name: 'elettricita_e_gas', value: 'elettricità e gas' }, { name: 'mobilita_elettrica', value: 'mobilità elettrica' }, { name: 'efficienza_energetica', value: 'efficienza energetica' }]
            }
        },
        buttons: {
            state_0: {
                placeholder: 'PROSEGUI',
                id: 'btn__firstState'
            },
            state_1: {
                placeholder: 'INVIA',
                id: 'btn__secondState'
            },
            state_2: {
                placeholder: 'INVIA',
                id: 'btn__thirdState'
            },
            errorMessage: 'Ops, qualcosa è andato storto. Riprova.'
        },
        privacy: {
            acknowledgment: (_t) => `Ho letto <a id="${_t}_privacy" class="red">l’informativa privacy</a> e autorizzo ad essere contattato per le finalità sopra espresse`,
            marketing: (_t) => `Autorizzo ad essere contattato anche per finalità promozionali e di marketing <a id="${_t}_privacy2B" class="red">(art. 2 B)</a>`
        },
        phoneContact: {
            title: 'Preferisci parlare?',
            subtitle: 'Sentiamoci al telefono.',
            description: '<strong>Lasciaci il tuo numero.</strong><br>Ti chiamiamo noi.',
            back: 'INDIETRO',
            fields: {
                telefono: {
                    placeholder: 'Telefono'
                },
                quando: {
                    placeholder: 'Quando desideri essere contattato?'
                }
            },
            button: {
                placeholder: 'INVIA',
                id: 'btn__callmeback'

            },
            callus: {
                id: 'btn__clicktocall',
                title: 'oppure chiama',
                number: '800 903 900',
                info: 'da lunedì a venerdì dalle 8.30 alle 18.30',
                action: 'tel:800903900'
            }
        }
    },
    sections: {
        elettricita_gas: {
            title: 'PARTI IN QUARTA',
            description: 'Trasforma subito la mobilità elettrica in un’opportunità di business.',
            img: 'elettricita_gas.jpg',
            box: {
                items: [
                    {
                        id: 'btn__ee_attivita',
                        menu: 'TAKE E-WAY',
                        icon: 'ico-salvadanaio.svg',
                        title: 'Il servizio di ricarica che fa crescere il tuo business.',
                        headline: null,
                        description: 'Diventa adesso il fornitore di ricariche per auto elettriche con Repower Charging Net e trasforma la mobilità elettrica in una nuova fonte di guadagno.<br />Per la tua attività scegli un servizio nuovo, distintivo e sostenibile. Semplice da scegliere, semplice da gestire.',
                        button: [{
                            id: 'btn__quarta_rcndownload-A',
                            label: 'LA RICARICA PRÊT-À-PORTER',
                            href: 'https://www.repower.com/media/685066/repower_charging_net.pdf'
                        }],
                        image: 'takeeway'
                    },
                    {
                        id: 'btn__ee_pmi',
                        menu: 'RICARICHE 100% GREEN',
                        icon: 'ico-garanzieorigine.svg',
                        title: 'Un presupposto differenziante',
                        headline: null,
                        description: 'Con Repower Charging Net tutti i chilowattora erogati sono coperti da Garanzie di Origine, certicati emessi dal Gestore dei Servizi Energetici (GSE), che attestano l’origine da fonti rinnovabili dell’energia erogata per le ricariche.',
                        button: [{
                            id: 'btn__quarta_rcndownload-B',
                            label: 'LA RICARICA PRÊT-À-PORTER',
                            href: 'https://www.repower.com/media/685066/repower_charging_net.pdf'
                        }],
                        image: 'ricarichegreen',
                    },
                    {
                        id: 'btn__qua_fatti_strada',
                        menu: 'FATTI STRADA',
                        icon: 'ico-hub.svg',
                        title: 'L’hub che si adatta allo spazio disponibile: componi il tuo.',
                        headline: null,
                        description: 'Ti basta avere in azienda un’area di sosta accessibile al pubblico dove consentire la ricarica di un’auto elettrica e il più è fatto! Componi l’abbonamento in base alle tue esigenze, scegliendo tra GIOTTO e SYMBIOSIS, gli esclusivi strumenti di ricarica in comodato d’uso, disponibili in diverse configurazioni, anche in previsione di un ampliamento futuro.',
                        button: [{
                            id: 'btn__quarta_giottodownload',
                            label: 'LEAFLET GIOTTO',
                            href: 'https://www.repower.com/media/683005/leaflet_giotto.pdf'
                        },
                        {
                            id: 'btn__quarta_symbiosisdownload',
                            label: 'LEAFLET SYMBIOSIS',
                            href: 'https://www.repower.com/media/683982/leaflet_symbiosis.pdf'
                        }],
                        image: 'fattistrada',
                    }
                ]
            }
        },
        mobilita_elettrica: {
            title: 'INGRANA LA QUINTA',
            description: 'Un network di vantaggi e opportunità.<br/>Sarebbe uno spreco non farne parte!',
            img: 'mobilita_elettrica.jpg',
            box: {
                items: [
                    {
                        id: 'btn__mobilita_strumenti',
                        menu: 'LOOK AND FEEL',
                        icon: 'ico-circuiti.svg',
                        title: 'Il circuito di ricarica che non passa inosservato.',
                        headline: null,
                        description: 'Per condividere con tutti il nuovo servizio, incluso nell’abbonamento c’è il kit di comunicazione pronto all’uso, fatto di strumenti digitali per il tuo sito web e i tuoi canali social ma soprattutto di strumenti fisici di prossimità. Oggetti che rendono visibile la stazione di ricarica anche a distanza e promuovono il servizio offerto e l’appartenenza al circuito in maniera distintiva e coordinata.',
                        button: [{
                            id: 'btn__quinta_sdcdownload',
                            label: 'STRUMENTI DI COMUNICAZIONE',
                            href: 'https://www.repower.com/media/685065/strumenti_di_comunicazione_di_prossimita.pdf'
                        }],
                        image: 'lookefeel',
                    },
                    {
                        id: 'btn__quinta_ineapp',
                        menu: 'IN & APP',
                        icon: 'ico-app.svg',
                        title: 'Il circuito di (app)partenenza.',
                        headline: null,
                        description: 'Repower Charging Net è integrato con Intercharge, la principale piattaforma di roaming internazionale per la ricarica elettrica e con Recharge Around, l’app che non ti lascia mai a piedi anche con la batteria scarica e rileva tutti i punti di ricarica attivi sul territorio. Su questi canali, con i quali i driver interagiscono costantemente, darai visibilità ulteriore alla tua struttura.',
                        button: [],
                        image: 'ineapp',
                    },
                    {
                        id: 'btn__mobilita_flotta',
                        menu: 'CHIAVI IN MANO',
                        icon: 'ico-facce.svg',
                        title: 'Pacchetto full optional',
                        headline: null,
                        description: 'Oltre alla stazione di ricarica e agli strumenti di comunicazione e promozione in comodato d’uso, puoi contare su consulenza e assistenza fin dall’installazione e su formazione continua attraverso la Charging Academy, per sfruttare al meglio le opportunità di questo nuovo business. Pensiamo noi anche alla gestione completa dei pagamenti con rendicontazione mensile.',
                        button: [],
                        image: 'chiaviinmano'
                    }
                ]
            }
        }

    },
    cover: {
        title: 'THAT’S THE <span style="font-family: TheMixBold">POWER</span> OF LOVE',
        label: 'Scegli l’energia che ama il tuo lavoro: efficienza energetica e mobilità elettrica, elettricità e gas inclusi.',
        slider: ['Cover_landing_1.jpg', 'Cover_landing_2.jpg', 'Cover_landing_3.jpg', 'Cover_landing_4.jpg']
    },
    footer: {
        menu: [
            {
                type: 'privacy',
                name: 'Privacy'
            },
            {
                type: 'cookie',
                name: 'Cookie'
            }
        ],
        info: 'Repower Vendita Italia spa<br>codice fiscale e partita iva 13181080154'
    },
    cookie: `Questo sito utilizza cookie tecnici e di profilazione, anche di terze parti, per le funzioni ordinarie di navigazione e per i contenuti personalizzati. Cliccando sul tasto “accetto”, scorrendo questa pagina o cliccando qualunque suo elemento, presti il consenso all’utilizzo dei cookie. Per saperne di più ed eventualmente disabilitare i cookie, consulta le informative su <a href="#" id="cookieModal">cookie</a> e <a href="#" id="privacyModal">privacy</a>.`

}