<template>
        <div 
            id="btn__toggleForm"
            class="call-me-container"
            @click="changeParentState">

            <div class="relative-container">
                
                <transition name="horizontal-left">
                    <div 
                        v-if="!isOpen"
                        class="call-me-row">
                        <div class="call-me-description">
                            <p 
                                class="small-title"
                                v-html="text.form.phoneContact.title"></p>
                            <p 
                                class="small-text"
                                v-html="text.form.phoneContact.subtitle"></p>
                        </div>
                    </div>
                </transition>

            
                <transition name="horizontal-right">
                    <div 
                        v-if="isOpen"
                        class="call-me-row back">
                        <img alt="Indietro" src="~@/assets/img/ico-func-dark-chevron-left.svg"/>
                        <p class="medium-text call-to">{{text.form.phoneContact.back}}</p>
                    </div>
                </transition>

                <transition name="vertical">
                    <div 
                        class="call-me-icon"
                        v-show="!isOpen">
                        <img alt="Telefono" src="~@/assets/img/ico-func-red-phone.svg"/>
                    </div>
                </transition>
            </div>
        </div>
</template>

<script>
    import {
        sasClick
    } from '../../helpers/tracking'

    import text from '../../assets/text/text'


    export default {
        name: 'CallMe',
        data: () => ({
            text: text,
            isOpen: false
        }),
        methods: {
            changeParentState: function(){
                
                let _id = this.$el.id
                //this.$emit('click')
                this.isOpen = !this.isOpen

                if(_id){
                    sasClick(_id);
                }
            },
            close: function(){
                this.isOpen = false
            }
        }
    }
</script>

<style scoped>

</style>