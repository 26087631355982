<template>
    <div>

        <div class="mask">
            <div
                :style="{backgroundImage: 'url('+heroImg+')', backgroundColor: '#000000'}"
                class="hero-img" role="presentation"></div>
            <div class="hero-mask"></div>
        </div>

        <video
            name="Video sfondo"
            aria-label="Video sfondo"
            id="heroVideo"
            class="hero-video"
            loop 
            autoplay
            playsinline
            muted>
            <source 
                :src="video"
                name="Video sfondo"
                type="video/mp4"
                alt="Repower video"
                @error="videoErrorHandler" >
        </video>
    </div>
    
</template>

<script>
    /* import ScrollMagic from 'scrollmagic'
    import 'ScrollMagicGSAP'
    import 'ScrollMagicIndicators' */

    export default {
        name: 'VideoPlayer',
        data: () => ({
            videoError: false,
            heroImg: require('@/assets/img/hero.jpg'),
            video: require('@/assets/img/hero.mp4')
        }),
        methods: {
            videoErrorHandler: function(){
                this.videoError = true;
            },
            pausedHandler: function(){
                let video = document.getElementById('heroVideo')
                if(video.paused)
                    video.play()
            },
            playedHandler: function(){
                let video = document.getElementById('heroVideo')
                video.pause()
            }
        },
        mounted: function(){
            let video = document.getElementById('heroVideo');
            
            if(video){
                video.play();
            }

            /*
            let controller = new ScrollMagic.Controller();
            let firstElement = document.getElementById('bodyContainer');

             new ScrollMagic.Scene({
                triggerElement: 'body',
                triggerHook: 'onLeave',
                offset: 200
            })
            .on('start', this.pausedHandler)
            .addTo(controller)


            new ScrollMagic.Scene({
                triggerElement: firstElement,
                triggerHook: 'onLeave',
            })
            .on('enter', this.playedHandler)
            .on('leave', this.pausedHandler)
            .addTo(controller) */

        }
    }
</script>


