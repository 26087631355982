window.googleTagManagerId = 'GTM-5W6H4NR2';
window.googleAnalyticsId = 'G-4D4NQJ2PT0';
window.googleAdsId = 'AW-974174490';

printCookies();


function printCookies() {
    return false;
}
